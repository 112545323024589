<template>
    <!-- ==================>Thông báo thành công<===================== -->
    <div v-if="Params.state == 'Success'">
        <div class="text-xs-center">
            <i
                class="mdi"
                :class="icon"
                :style="'color:' + color"
                style="font-size: 46px"
            ></i>
        </div>
        <div
            class="font-regular text-xs-center font-medium font-20"
            style="white-space: break-spaces"
            :style="`${breakAllText ? `word-break: break-all` : ``}`"
        >
            {{ message_title }}
        </div>
        <p class="text-xs-center py-2">{{ message }}</p>
        <div class="text-xs-center">
            <DxButton
                :text="button"
                width="83"
                style="text-transform: uppercase; color: white"
                :style="'background-color:' + color"
                id="btnThongBao-Ok"
                @click="click(true)"
            ></DxButton>
        </div>
    </div>
    <!-- ==================>Thông báo lỗi<===================== -->
    <div v-if="Params.state == 'Error'">
        <div
            class="font-medium font-20"
            style="text-transform: uppercase"
            :style="'color:' + color"
        >
            {{ title }}
        </div>
        <div class="row">
            <div class="frame-left">
                <i
                    class="mdi"
                    :class="icon"
                    style="font-size: 48px"
                    :style="'color:' + color"
                ></i>
            </div>
            <div class="frame-right">
                <div :class="!message_title || !message ? 'column justify-center' : ''">
                    <div class="font-medium font-16">
                        {{ message_title }}
                    </div>
                    <div
                        class="font-16"
                        style="white-space: break-spaces"
                        :style="`${breakAllText ? `word-break: break-all` : ``}`"
                    >
                        {{ message }}
                    </div>
                </div>
            </div>
        </div>
        <div class="row justify-end">
            <DxButton
                :text="button"
                width="83"
                style="text-transform: uppercase; color: white"
                :style="'background-color:' + color"
                id="btnThongBao-Ok"
                @click="click(true)"
            ></DxButton>
        </div>
    </div>
    <!-- ==================>Thông báo cảnh báo<===================== -->
    <div v-if="Params.state == 'Warning'">
        <div class="row jutify-end">
            <div class="frame-left"></div>
            <div class="frame-right">
                <div
                    class="font-medium font-20"
                    style="text-transform: uppercase"
                    :style="'color:' + color"
                >
                    {{ title }}
                </div>
            </div>
        </div>
        <div class="row">
            <div class="frame-left">
                <i
                    class="mdi"
                    :class="icon"
                    style="font-size: 48px"
                    :style="'color:' + color"
                ></i>
            </div>
            <div class="frame-right pt-2">
                <div class="font-medium font-16">
                    {{ message_title }}
                </div>
                <div
                    class="font-16"
                    style="white-space: break-spaces"
                    :style="`${breakAllText ? `word-break: break-all` : ``}`"
                >
                    {{ message }}
                </div>
            </div>
        </div>
        <div
            class="row mt-3"
            :class="ButtonLeft && ButtonRight ? 'justify-space-between' : 'justify-end'"
            v-if="ButtonLeft || ButtonRight"
        >
            <DxButton
                :text="button_cancel"
                v-if="ButtonLeft"
                style="text-transform: uppercase"
                styling-mode="text"
                type="danger"
                @click="click(false)"
            ></DxButton>
            <DxButton
                :text="button_save"
                v-if="ButtonRight"
                style="text-transform: uppercase; color: white"
                type="default"
                id="btnThongBao-Ok"
                @click="click(true)"
            ></DxButton>
        </div>
    </div>
    <!-- ==================>Thông báo câu hỏi<===================== -->
    <div v-if="Params.state == 'Question'">
        <div class="row jutify-end">
            <div class="frame-left"></div>
            <div class="frame-right">
                <div
                    class="font-medium font-20"
                    style="text-transform: uppercase"
                    :style="'color:' + color"
                >
                    {{ title }}
                </div>
            </div>
        </div>
        <div class="row">
            <div class="frame-left">
                <i
                    class="mdi"
                    :class="icon"
                    style="font-size: 48px"
                    :style="'color:' + color"
                ></i>
            </div>
            <div class="frame-right pt-2">
                <div class="font-medium font-16">
                    {{ message_title }}
                </div>
                <div
                    class="font-16"
                    style="white-space: break-spaces"
                    :style="`${breakAllText ? `word-break: break-all` : ``}`"
                >
                    {{ message }}
                </div>
            </div>
        </div>
        <div
            class="row mt-3"
            :class="ButtonLeft && ButtonRight ? 'justify-space-between' : 'justify-end'"
            v-if="ButtonLeft || ButtonRight"
        >
            <DxButton
                :text="button_cancel"
                v-if="ButtonLeft"
                style="text-transform: uppercase"
                styling-mode="text"
                type="danger"
                @click="click(false)"
            ></DxButton>
            <DxButton
                :text="button_save"
                v-if="ButtonRight"
                style="text-transform: uppercase; color: white"
                type="default"
                id="btnThongBao-Ok"
                @click="click(true)"
            ></DxButton>
        </div>
    </div>
    <!-- ==================>Thông báo thông tin<===================== -->
    <div v-if="Params.state == 'Info'">
        <div class="row jutify-end">
            <div class="frame-left"></div>
            <div class="frame-right">
                <div
                    class="font-medium font-20"
                    style="text-transform: uppercase"
                    :style="'color:' + color"
                >
                    {{ title }}
                </div>
            </div>
        </div>
        <div class="row">
            <div class="frame-left">
                <i
                    class="mdi"
                    :class="icon"
                    style="font-size: 48px"
                    :style="'color:' + color"
                ></i>
            </div>
            <div class="frame-right pt-2">
                <div class="font-medium font-16">
                    {{ message_title }}
                </div>
                <div
                    class="font-16"
                    style="white-space: break-spaces"
                    :style="`${breakAllText ? `word-break: break-all` : ``}`"
                >
                    {{ message }}
                </div>
            </div>
        </div>
        <div
            class="row mt-3"
            :class="ButtonLeft && ButtonRight ? 'justify-space-between' : 'justify-end'"
            v-if="ButtonLeft || ButtonRight"
        >
            <DxButton
                :text="button_cancel"
                v-if="ButtonLeft"
                style="text-transform: uppercase"
                styling-mode="text"
                type="danger"
                @click="click(false)"
            ></DxButton>
            <DxButton
                :text="button_save"
                v-if="ButtonRight"
                style="text-transform: uppercase; color: white"
                type="default"
                id="btnThongBao-Ok"
                @click="click(true)"
            ></DxButton>
        </div>
    </div>
</template>
<script>
/* eslint-disable */
import { DxButton } from "devextreme-vue";

export default {
    components: { DxButton },
    props: {
        Params: {
            type: Object,
            default: () => {},
        },
        ButtonLeft: { type: Boolean, default: true },
        ButtonRight: { type: Boolean, default: true },
        breakAllText: { type: Boolean, default: false },
    },
    data() {
        return {
            title: this.Params.title,
            icon: this.Params.icon,
            message_title: this.Params.message_title,
            message: this.Params.message,
            color: this.Params.color,
            button: this.Params.button,
            button_save: this.Params.button_save,
            button_cancel: this.Params.button_cancel,
        };
    },
    methods: {
        error() {
            this.title = this.Params.title || "Thông báo!";
            this.icon = this.Params.icon || "mdi-alert-circle";
            this.color = this.Params.color || "#D10909";
            this.button = this.Params.button || "ok";
            this.message = this.Params.message;
            this.message_title = this.Params.message_title;
        },
        success() {
            this.color = this.Params.color || "#13B000";
            this.button = this.Params.button || "ok";
            this.title = this.Params.title;
            this.icon = this.Params.icon || "mdi-check-circle-outline";
            this.message = this.Params.message;
            this.message_title = this.Params.message_title;
        },
        warning() {
            this.title = this.Params.title || "Cảnh báo";
            this.icon = this.Params.icon || "mdi-alert";
            this.color = this.Params.color || "#FB8C00";
            this.button_save = this.Params.button_save || "Đồng ý";
            this.button_cancel = this.Params.button_cancel || "Hủy";
            this.message = this.Params.message;
            this.message_title = this.Params.message_title;
        },
        info() {
            this.title = this.Params.title || "Thông tin";
            this.icon = this.Params.icon || "mdi-information";
            this.color = this.Params.color || "#03a9f4";
            this.button_save = this.Params.button_save || "Đồng ý";
            this.button_cancel = this.Params.button_cancel || "Hủy";
            this.message = this.Params.message;
            this.message_title = this.Params.message_title;
        },
        question() {
            this.title = this.Params.title || "Thông báo";
            this.icon = this.Params.icon || "mdi-help-circle-outline";
            this.color = this.Params.color || "#03a9f4";
            this.button_save = this.Params.button_save || "Đồng ý";
            this.button_cancel = this.Params.button_cancel || "Hủy";
            this.message = this.Params.message;
            this.message_title = this.Params.message_title;
        },
        click(params) {
            this.$emit("click", params);
        },
    },
    watch: {
        Params: {
            immediate: true,
            handler: function (val) {
                switch (this.Params.state) {
                    case "Success":
                        this.success();
                        break;
                    case "Warning":
                        this.warning();
                        break;
                    case "Error":
                        this.error();
                        break;
                    case "Info":
                        this.info();
                        break;
                    case "Question":
                        this.question();
                        break;
                    default:
                        this.icon = "mdi-alert-circle";
                        this.color_icon = "warning";
                        this.color_button = "warning";
                        this.color_text = "warning";
                        this.title = "Đã xảy ra lỗi !";
                        break;
                }
            },
            deep: true,
        },
    },
};
</script>
<style scoped>
.frame-left {
    width: 48px;
    margin-right: 16px;
}
.frame-right {
    width: calc(100% - 48px - 16px);
}
</style>
