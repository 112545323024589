<template>
    <ion-page>
        <ion-toolbar
            style="--background: #03a9f4; color: white; --min-height: 36px"
            class="custom-toolbar"
        >
            <div class="row align-center">
                <slot name="before">
                    <DxButton
                        v-if="!showSelection"
                        icon="mdi mdi-checkbox-multiple-marked-circle-outline"
                        type="default"
                        @click="showSelection = true"
                    />
                    <DxButton
                        v-if="showSelection"
                        icon="mdi mdi-close-circle-outline"
                        type="default"
                        class="mr-1"
                        @click="
                            showSelection = false;
                            IsChonTatCa = false;
                            BoChonTatCa();
                        "
                    />
                    <DxButton
                        v-if="showSelection"
                        icon="mdi mdi-chevron-left"
                        type="default"
                        style="opacity: 0"
                    />
                </slot>
                <slot name="center">
                    <div class="grow text-xs-center">THÔNG BÁO</div>
                </slot>
                <slot name="after">
                    <DxButton
                        v-if="!showSelection"
                        icon="mdi mdi-reload"
                        type="default"
                        @click="
                            DanhSachThongBao = [];
                            LayDanhSachThongBao();
                        "
                    />
                    <DxButton
                        v-if="showSelection"
                        icon="mdi mdi-notification-clear-all"
                        type="default"
                        class="mr-1"
                        :disabled="ItemSelected.length == 0"
                        @click="XacNhanDaDocThongBao"
                    />
                    <DxButton
                        v-if="showSelection"
                        icon="mdi mdi-delete-empty-outline"
                        type="default"
                        :disabled="ItemSelected.length == 0"
                        @click="
                            isXoaMotThongBao = false;
                            showPopup('PopupXacNhanXoa');
                        "
                    />
                </slot>
            </div>
        </ion-toolbar>

        <ion-content :scroll="false">
            <!-- <div id="testFCMTOk" style="user-select: text"></div> -->

            <div
                class="row pt-3 pl-2 pb-2"
                style="border-bottom: 1px solid #e9e9e9"
                v-if="showSelection"
            >
                <DxCheckBox
                    v-model="IsChonTatCa"
                    text="Chọn tất cả"
                    :onValueChanged="onValueChangedChonTatCa"
                />
            </div>

            <div class="row justify-center pa-3" v-if="DanhSachThongBao.length == 0">
                Không có thông báo mới!
            </div>
            <div
                class="frame-thong-bao"
                :style="`height: ${
                    showSelection
                        ? `calc(100vh - ${DaLayHetThongBao ? `127px` : `167px`})`
                        : `calc(100vh - ${DaLayHetThongBao ? `80px` : `120px`})`
                }`"
                v-else
            >
                <div
                    class="row align-center mt-3"
                    v-for="(item, index) in DanhSachThongBao"
                    :key="item"
                >
                    <DxCheckBox v-if="showSelection" class="mr-2" v-model="item.DaChon" />
                    <div class="xs12">
                        <ThongBaoVue :item="item" @XoaMotThongBao="XoaMotThongBao" />
                    </div>
                </div>
            </div>

            <!-- <DxList
                ref="DanhSachThongBao"
                :data-source="DanhSachThongBao"
                page-load-mode="scrollBottom"
                :hoverStateEnabled="false"
                :focusStateEnabled="false"
                :activeStateEnabled="false"
                no-data-text="Không có thông báo mới!"
                class="list-style"
                :height="
                    showSelection
                        ? `calc(100vh - ${DaLayHetThongBao ? `119px` : `159px`})`
                        : `calc(100vh - ${DaLayHetThongBao ? `80px` : `120px`})`
                "
                :selectionMode="showSelection ? `multiple` : `none`"
                keyExpr="idThongBao"
                :showSelectionControls="showSelection"
                v-model:selected-item-keys="ItemSelected"
            >
                <template #item="{ data: item }">
                    <div>
                        <div style="padding: 8px">
                            <ThongBaoVue :item="item" @XoaMotThongBao="XoaMotThongBao" />
                        </div>
                    </div>
                </template>
            </DxList> -->

            <div class="row justify-center mt-1 pb-1">
                <DxButton
                    v-if="!DaLayHetThongBao"
                    text="Xem thêm"
                    type="default"
                    styling-mode="contained"
                    @click="LayDanhSachThongBao()"
                />
            </div>

            <DxPopup
                v-if="ifPopupXacNhanXoa"
                v-model:visible="PopupXacNhanXoa"
                width="90%"
                height="auto"
                :show-title="false"
                :close-on-outside-click="false"
                :drag-enabled="false"
                :show-close-button="false"
                @hidden="onHiddenPopup('PopupXacNhanXoa')"
                :wrapperAttr="{
                    class: `popup-padding-16`,
                }"
            >
                <NotificationVue :Params="ParamsThongBao" @click="XacNhanThongBao" />
            </DxPopup>
        </ion-content>
    </ion-page>
</template>
<script>
import ThanhTieuDeVue from "../../../src/components/_Common/ThanhTieuDe";
import ThongBaoVue from "./components/ThongBao.vue";
import { DxList, DxToast, DxPopup, DxCheckBox } from "devextreme-vue";
import { IonPage, IonContent, onIonViewWillEnter } from "@ionic/vue";
import { DxButton } from "devextreme-vue";
import NotificationVue from "../../components/_Common/Notification.vue";

export default {
    components: {
        DxCheckBox,
        ThanhTieuDeVue,
        ThongBaoVue,
        DxList,
        DxToast,
        IonPage,
        IonContent,
        DxButton,
        DxPopup,
        NotificationVue,
    },
    props: {},
    data() {
        return {
            DanhSachThongBao: [],
            showSelection: false,
            ifPopupXacNhanXoa: false,
            PopupXacNhanXoa: false,
            ParamsThongBao: {
                state: "Question",
                button_save: "Đồng ý",
                button_cancel: "Hủy",
                title: "Xác nhận!",
                message_title: "Bạn chắc chắn xóa thông báo đã chọn?",
            },
            IsChonTatCa: false,
            DaLayHetThongBao: false,
            IdThongBao: null,
            isXoaMotThongBao: false,
        };
    },
    computed: {
        SoLuongThongBao() {
            return 0;
        },
        ItemSelected() {
            return this.DanhSachThongBao.filter((e) => e.DaChon).map((e) => e.idThongBao);
        },
    },
    methods: {
        onHiddenPopup(name) {
            this["if" + name] = false;
        },
        hiddenPopup(name) {
            this[name] = false;
        },
        showPopup(name) {
            this["if" + name] = true;
            this[name] = true;
        },
        async LayDanhSachThongBao() {
            try {
                this.$startLoading;
                this.DaLayHetThongBao = false;
                let DanhSachThongBao = await this.$store.dispatch(
                    "ThongBao/LayDanhSachThongBao",
                    {
                        skip: this.DanhSachThongBao.length,
                    },
                );
                DanhSachThongBao.forEach((e) => {
                    e.DaChon = false;
                });
                this.DanhSachThongBao.push(...DanhSachThongBao);
                if (this.IsChonTatCa) {
                    this.ChonTatCa();
                }
                if (DanhSachThongBao.length == 0) {
                    this.DaLayHetThongBao = true;
                }
            } catch (error) {
                console.log("🚀 ~ LayDanhSachThongBao ~ error:", error);
            } finally {
                this.$stopLoading;
            }
        },
        XoaMotThongBao(idThongBao) {
            this.isXoaMotThongBao = true;
            this.IdThongBao = idThongBao;
            this.showPopup("PopupXacNhanXoa");
        },
        XacNhanThongBao(bool) {
            if (bool == true) {
                this.ThucHienXoaThongBao();
                this.hiddenPopup("PopupXacNhanXoa");
            } else {
                this.hiddenPopup("PopupXacNhanXoa");
            }
        },
        async ThucHienXoaThongBao() {
            try {
                let rs = await this.$Events.RequestPost({
                    keyRequest: "ThucHienXoaThongBao",
                    ServicesBE: "ThongBao",
                    url: this.$t("urlThongBao.XoaThongBao"),
                    dataPost: {
                        danhSachIdThongBao: this.isXoaMotThongBao
                            ? [this.IdThongBao]
                            : this.IsChonTatCa
                            ? []
                            : this.ItemSelected,
                        tatCaThongBao: this.IsChonTatCa,
                    },
                    msgSuccess: "Xóa thông báo thành công!",
                    msgError: "Xóa thông báo thất bại!",
                    msgCatch: "Xóa thông báo thất bại!",
                    typeNotificationSuccess: "Toast",
                    typeNotificationError: "Toast",
                });
                if (rs.status) {
                    this.IsChonTatCa = false;
                    this.BoChonTatCa();
                    this.DanhSachThongBao = [];
                    this.showSelection = false;
                    this.LayDanhSachThongBao();
                }
            } catch (error) {
                console.log("🚀 ~ ThucHienXoaThongBao ~ error:", error);
            }
        },
        async XacNhanDaDocThongBao() {
            try {
                let rs = await this.$Events.RequestPost({
                    keyRequest: "XacNhanDaDocThongBao",
                    ServicesBE: "ThongBao",
                    url: this.$t("urlThongBao.XacNhanDaDocThongBao"),
                    dataPost: {
                        danhSachIdThongBao: this.isXoaMotThongBao
                            ? [this.IdThongBao]
                            : this.IsChonTatCa
                            ? []
                            : this.ItemSelected,
                        tatCaThongBao: this.IsChonTatCa,
                    },
                    msgSuccess: "Đánh dấu đã đọc thông báo thành công!",
                    msgError: "Đánh dấu đã đọc thông báo thất bại!",
                    msgCatch: "Đánh dấu đã đọc thông báo thất bại!",
                    typeNotificationSuccess: "Toast",
                    typeNotificationError: "Toast",
                });
                if (rs.status) {
                    this.IsChonTatCa = false;
                    this.BoChonTatCa();
                    this.DanhSachThongBao = [];
                    this.showSelection = false;
                    this.LayDanhSachThongBao();
                }
            } catch (error) {
                console.log("🚀 ~ ThucHienXoaThongBao ~ error:", error);
            }
        },
        async LayChiTietThongBao(idThongBao) {
            try {
                let rs = await this.$Events.RequestGet({
                    ServicesBE: "ThongBao",
                    url: this.$t("urlThongBao.ChiTietThongBao"),
                    dataGet: { idThongBao: idThongBao },
                    msgSuccess: "",
                    msgError: "Lấy chi tiết thông báo thất bại!",
                    msgCatch: "Lấy chi tiết thông báo thất bại!",
                });
                if (!rs.status) {
                    return;
                }
                let data = rs.data;
                // document.getElementById("testFCMTOk").innerText +=
                //     `\n- 2` + JSON.stringify(data);
                if (
                    data.maHanhDong == this.$t("MaHanhDongThongBao.DOANH_NGHIEP_KY_LENH")
                ) {
                    this.$router.push(
                        "/Thong-Bao/Chi-Tiet?idThongBao=" + data.idThongBao,
                    );
                } else {
                    let itemLenh = JSON.parse(data.duLieu);

                    setTimeout(() => {
                        this.$router.push({
                            path: "/tabs/LenhDienTu",
                            query: {
                                isChiTiet: true,
                                guidLenh: itemLenh.IdLenh,
                                laLenhGiay: itemLenh.hasOwnProperty("LaLenhDienTu")
                                    ? !itemLenh.LaLenhDienTu
                                    : false,
                            },
                        });
                    }, 100);
                }
            } catch (error) {
                console.log("🚀 ~ LayChiTietThongBao ~ error:", error);
            }
        },
        onValueChangedChonTatCa(e) {
            if (e.value == true) {
                this.ChonTatCa();
            } else if (e.value == false) {
                this.BoChonTatCa();
            }
        },
        ChonTatCa() {
            this.DanhSachThongBao.forEach((e) => {
                e.DaChon = true;
            });
        },
        BoChonTatCa() {
            this.DanhSachThongBao.forEach((e) => {
                e.DaChon = false;
            });
        },
    },
    created() {
        let self = this;
        onIonViewWillEnter(() => {
            self.IsChonTatCa = false;
            self.BoChonTatCa();
            self.DanhSachThongBao = [];
            self.showSelection = false;
            self.LayDanhSachThongBao();
            let idThongBao = localStorage.getItem("IdThongBaoWhenOpenNotification");
            // document.getElementById("testFCMTOk").innerText +=
            //     `\n- 1` + JSON.stringify(query);
            if (idThongBao) {
                self.LayChiTietThongBao(idThongBao);
                localStorage.removeItem("IdThongBaoWhenOpenNotification");
            }
        });
        window.addEventListener("user-native-opening-notification", (e) => {
            // document.getElementById("testFCMTOk").innerText +=
            //     `\n- 1` + JSON.stringify(e.data);
            if (e.data?.raw?.idThongBao) {
                self.LayChiTietThongBao(e.data?.raw?.idThongBao);
            }
        });
    },
    mounted() {},
};
</script>
<style scoped>
>>> .dx-list-item-content {
    padding: unset !important;
}
.frame-thong-bao {
    overflow-x: hidden;
    overflow-y: auto;
    padding: 0 8px;
    padding-bottom: 8px;
}
</style>
<style lang="scss" scoped>
// .dx-template-wrapper.dx-item-content.dx-list-item-content {
//     border-bottom: 1px solid $border;
// }
.notify-unread {
    position: fixed;
    right: 16px;
    bottom: 2%;
    background-color: rgba(244, 3, 95, 0.8) !important;
    padding: 4px 8px;
    box-shadow: 0 2px 9px #dadce0;
    border-radius: 5px;
    color: white;
    transition: all 0.2s ease;
    width: 158px;

    overflow: hidden;
}

.notify-unread.scroll {
    width: 40px;
    transition: all 0.2s ease;
    text-align: center;
}

.notify-unread-text {
    display: inline-block;
    position: absolute;
    right: 8px;
    transition: all 0.2s ease;
    visibility: visible;
}

.notify-unread-text.scroll {
    display: inline-block;
    visibility: hidden;
    right: -120px;

    transition: all 0.2s ease;
}
#testFCMTOk {
    width: 100vw;
    height: 30vh;
    overflow: auto;
    background: red;
    color: #fff;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 10000;
}
</style>
